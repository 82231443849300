import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ChatContainer, MainContainer, Message, MessageInput, MessageList, TypingIndicator, InputToolbox } from '@chatscope/chat-ui-kit-react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import './chat.css';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeFormat from 'rehype-format';
import rehypeHighlight from 'rehype-highlight';
import { Box, IconButton, Button } from '@mui/material';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { Auth, Storage } from 'aws-amplify';
import { captureException, setContext } from '@sentry/react';
import Declaimer from 'Declaimer';
import { useAppContext } from 'context/App';
import { type ChatGptMessageEvent } from 'types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { type PromptsModalState } from 'components/Prompts';
import Prompts from 'components/Prompts';
import HeartIcon from 'components/Prompts/HeartIcon';
import AppToaster from 'components/AppToaster';
import { CopyCodeButton } from 'components/CopyCodeButton';
import 'highlight.js/styles/a11y-dark.css';
import { addConnectivityIssueBreadcrumb } from 'helpers/sentry';
import FileCard from 'components/CardFile';
import { sanitizeText } from 'helpers';

interface MessageChat {
  text: string
  sender: 'BOT' | 'USER' | 'FILE'
  className?: string
}

interface ServerMessage {
  content: string
  role: 'assistant' | 'user'
}

interface UploadedFile {
  key: string
  name: string
  type: string
}

/**
 * Funzione che ritorna l'endpoint della websocket
 * con il token utente ed una key random
 *
 * @returns string url della socket.
 */
const getSocketEndpoint = async (): Promise<string> => {
  const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
  const url = `${process.env.REACT_APP_WS_ENDPOINT}?token=${token}&key=${Math.random()}`;
  return url;
};

function createMessage (item: MessageChat, index: number, typing: boolean, isLastMessage: boolean, lastBotMessage: MessageChat | null, setPromptModalState: React.Dispatch<React.SetStateAction<PromptsModalState>>): JSX.Element {
  return (
    <Message
      key={`index-${index}`}
      className={item.className}
      model={{
        direction: item.sender === 'BOT' ? 'incoming' : 'outgoing',
        sender: item.sender,
        position: 'single'
      }}>
      <Message.CustomContent>
        <Box display="flex">
          <Box pr={item.sender !== 'BOT' ? 1 : 0} >
            {item.sender === 'BOT' && (<ReactMarkdown components={{
              table: ({ node, children, ...props }) => <table border={1} style={{ border: '1px solid #000', borderCollapse: 'collapse' }} {...props}>{children}</table>,
              td: ({ node, children, isHeader, ...props }) => <td style={{ paddingLeft: '8px', paddingRight: '8px' }} {...props}>{children}</td>,
              th: ({ node, children, isHeader, ...props }) => <th style={{ paddingLeft: '8px', paddingRight: '8px' }} {...props}>{children}</th>,
              ul: ({ node, children, ordered, ...props }) => <ul style={{ paddingLeft: '40px' }} {...props}>{children}</ul>,
              ol: ({ node, children, ordered, ...props }) => <ol style={{ paddingLeft: '40px' }} {...props}>{children}</ol>,
              li: ({ node, children, ordered, ...props }) => <li style={{ whiteSpace: 'normal' }} {...props}>{children}</li>,
              pre: ({ node, children, ...props }) => <CopyCodeButton><pre style={{ whiteSpace: 'break-spaces' }} {...props}>{children}</pre></CopyCodeButton>
            }}
              rehypePlugins={[rehypeRaw, [rehypeHighlight, { ignoreMissing: true }], [rehypeFormat, { indentInitial: false }]]}
              remarkPlugins={[remarkGfm]} >{item.text}</ReactMarkdown>)}
            {item.sender !== 'BOT' && item.text }
        </Box>
        {item.sender === 'USER' &&
          <HeartIcon onClick={() => {
            setPromptModalState({
              open: true,
              editMode: true,
              promptText: item.text
            });
          }} />
        }
        { /* Mostro il bottone "Copia" in determinati casi, se il messaggio è del BOT (e non è di errore) sempre, treanne quando il messaggio
        è del BOT ma è quello che sta scrivendo in quel momento. La condizione isLastMessage && ! lastBotMessage serve per mostrare il bottone anche
        quando la chat sta scrivendo ma ancora non è presente il nuovo messaggio (altrimenti verrebbe nascosto quello precedente quando viene fatto il Rigenera risposta). */ }
        { item.sender === 'BOT' && item.className !== 'msg-error' && (!typing || !isLastMessage || (isLastMessage && (lastBotMessage == null))) &&
          <Box ml={2}>
            <IconButton onClick={() => { navigator.clipboard.writeText(item.text); } } sx={{ p: 0 }}>
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        }
        </Box>
      </Message.CustomContent>
    </Message>
  );
}

function generateOldMessages (old: MessageChat[]): ServerMessage[] {
  return old.map((item: MessageChat) => ({
    content: item.text,
    role: item.sender === 'BOT' ? 'assistant' : 'user'
  }));
}

const ALLOWED_FINISH_REASON = ['stop', 'length', 'end'];
const MAX_NUMBER_FILES = 20;
const MAX_SIZE_FILE = 536870912;
const MAX_SIZE_AUDIO_FILE = 26376056;

function Chat ({ temperature }: any): JSX.Element {
  const { user, authStatus } = useAuthenticator((context) => [context.user, context.authStatus]);
  const [identityId, setIdentityId] = useState('');

  // Connection id per la connessione all'api per inviare messaggi troppo lunghi.
  const [connectionId, setConnectionId] = useState<string | undefined>();

  const [enabledRegenerate, setEnabledRegenerate] = useState(true);

  /**
   * Stato con la lista dei messaggi, contiene due liste:
   * 1) messagesRendered è la lista dei messaggi da visualizzare, con più copie di risposte per la stessa domanda
   * nel caso del Rigenera risposta.
   * 2) messagesSocket è la lista di messaggi da utilizzare nella socket, quindi con una sola risposta per ciascuna domanda
   * fatta dall'utente.
   */
  const [messages, setMessages] = useState<{
    messagesRendered: MessageChat[]
    messagesSocket: MessageChat[]
  }>({
    messagesRendered: [],
    messagesSocket: []
  });

  const [files, setFiles] = useState<{
    fileList: UploadedFile[]
  }>({
    fileList: []
  });

  const [fileCount, setFileTot] = useState<{
    fileTot: number
  }>({
    fileTot: 0
  });

  const [threadData, setThreadData] = useState<{
    thread: {
      id?: string
      assistant?: string
      appendFiles?: string[]
    }
  }>({
    thread: {}
  });

  /**
   * Contiene il nuovo messaggio da inviare e lo stato regenerate per capire
   * se il messaggio è stato scritto dall'utente o si vuole rigenerare una risposta.
   */
  const [newMessage, setNewMessage] = useState<{
    msg: string
    regenerate: boolean
  } | undefined>();
  const [inputData, setInputData] = useState<{
    typing: boolean
    disabled: boolean
  }>({
    typing: false,
    disabled: false
  });
  const [inputText, setInputText] = useState('');

  const lastBotMessage = useRef<MessageChat | null>(null);
  const [currentChatId, setCurrentChatId] = useState<string | null>();
  const shouldChangeChatId = useRef(true);
  const { declaimerClosed } = useAppContext();

  // Stato per la modale dei prompts
  const [promptModalState, setPromptModalState] = useState<PromptsModalState>({
    open: false,
    editMode: false
  });

  // Variabili state relative alla socket.

  const [wsUrl, setWsUrl] = useState('');
  const [isWsOpen, setWsOpen] = useState<boolean>(false);
  /**
   * Usato a scopi di debug, salvo gli ultimi 5 messaggi
   * ricevuti dalla socket e l'ultimo stato prima della disconnessione.
   * Uso il ref per evitare di retriggerare render del componente
   */
  const rawSocketData = useRef<{
    messageHistory: string[]
    socketState: number | undefined
  }>({
    messageHistory: [],
    socketState: 0
  });
  const { sendMessage, lastMessage, getWebSocket, readyState } = useWebSocket(wsUrl, {
    shouldReconnect: () => true,
    onOpen: () => { setEnabledRegenerate(true); },
    onMessage: (event: MessageEvent<string>) => {
      rawSocketData.current.messageHistory.splice(0, 0, event.data);
      // Se ho superato i 5 messaggi, cancello il più vecchio;
      if (rawSocketData.current.messageHistory.length > 5) {
        rawSocketData.current.messageHistory.splice(-1, 1);
      }
      rawSocketData.current.socketState = event.currentTarget instanceof WebSocket ? event.currentTarget?.readyState : undefined;
    },
    reconnectInterval: 1000
  }, isWsOpen);

  const connectSocket = useCallback(async () => {
    const url: string = await getSocketEndpoint();
    setWsUrl(url);
    setWsOpen(true);
  }, []);

  const sendToSocket = async (newMessage: string | undefined, prevMessages: MessageChat[] = []): Promise<void> => {
    const body = {
      temperature: temperature / 100,
      text: newMessage,
      prevMessages: generateOldMessages(prevMessages),
      thread: {
        id: threadData.thread.id,
        assistant: threadData.thread.assistant,
        appendFiles: [...(files.fileList.map(file => file.key))]
      }
    };

    const formattedBody = JSON.stringify(body);

    if (formattedBody.length > 100000) {
      const token = `${(await Auth.currentSession()).getIdToken().getJwtToken()}`;

      await fetch(`${process.env.REACT_APP_PROMPT_ENDPOINT}/bigprompt`, {
        method: 'POST',
        body: JSON.stringify({
          connId: connectionId,
          payload: body
        }),
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    } else {
      sendMessage(formattedBody);
    }

    setFiles({ fileList: [] });
  };

  useEffect(() => {
    if (authStatus === 'authenticated') {
      Auth.currentUserCredentials().then((creds) => {
        setIdentityId(creds.identityId);
      });
    }
  }, [authStatus]);

  // recupero il token
  useEffect(() => {
    connectSocket();
  }, []);

  useEffect(() => {
    const lastMessageInQueue = messages.messagesSocket[messages.messagesSocket.length - 1];
    if ((lastMessage == null) || lastMessageInQueue?.className === 'msg-error') return;

    let msg: ChatGptMessageEvent = { choices: [] };
    try {
      msg = JSON.parse(lastMessage?.data);
    } catch (error) {
      captureException(error, {
        tags: {
          component: 'Chat',
          action: 'writeChatResponse',
          data: lastMessage?.data
        },
        user
      });
      setCurrentChatId(null);
      lastBotMessage.current = null;
      setInputData({
        typing: false,
        disabled: true
      });

      const errorMsg: MessageChat = {
        sender: 'BOT',
        text: 'Ops! Qualcosa è andato storto. Per favore, clicca su Reset Chat e reinserisci il prompt. Se il problema persiste, inviaci una richiesta di supporto! :)',
        className: 'msg-error'
      };

      setMessages({
        messagesRendered: [...messages.messagesRendered, errorMsg],
        messagesSocket: [...messages.messagesSocket, errorMsg]
      });
      console.error('error', error);
      return;
    }

    if (msg?.connectionId) {
      setConnectionId(msg?.connectionId);
      return;
    }

    if (msg?.thread) {
      setThreadData({ thread: msg.thread });
    }

    // Controllo se è arrivato un errore
    if (msg?.error ?? !msg?.choices) {
      setCurrentChatId(null);
      lastBotMessage.current = null;
      setInputData({
        typing: false,
        disabled: true
      });

      let errorMessage = '';
      switch (msg?.error?.code) {
        case 'context_length_exceeded': {
          errorMessage = 'Ops! Il messaggio che hai inserito è troppo lungo! Per favore, clicca su Reset Chat e inserisci un prompt più breve. Se il problema persiste, inviaci una richiesta di supporto! :)';
          break;
        }
        case 'file_format_not_supported': {
          errorMessage = 'Ops! Il formato del file non è supportato. Posso gestire i file con queste estensioni: c, cpp, css, csv, docx, gif, go, html, java, jpeg, jpg, js, json, md, pdf, php, pkl, png, pptx, py, rb, tar, tex, ts, txt, webp, xlsx, xml, zip. Per favore, clicca su Reset Chat e inserisci un file supportato.';
          break;
        }
        default: {
          captureException('errore generico nell\'utilizzo del messaggio', {
            level: 'info',
            tags: {
              component: 'Chat',
              action: 'errorMessage',
              error_code: msg?.error?.code,
              error_message: msg?.error?.message,
              msg: JSON.stringify(msg)
            },
            user
          });
          errorMessage = 'Ops! Qualcosa è andato storto. Per favore, clicca su Reset Chat e reinserisci il prompt. Se il problema persiste, inviaci una richiesta di supporto! :)';
        }
      }

      const errorMsg: MessageChat = {
        sender: 'BOT',
        text: errorMessage,
        className: 'msg-error'
      };

      setMessages({
        messagesRendered: [...messages.messagesRendered, errorMsg],
        messagesSocket: [...messages.messagesSocket, errorMsg]
      });
      return;
    }

    const lastMsgReason = msg?.choices[0]?.finish_reason ?? '';
    // ricevo la stop word, fine messaggio.
    if (ALLOWED_FINISH_REASON.includes(lastMsgReason)) {
      if (!(lastMsgReason === 'length')) {
        setCurrentChatId(null);
        lastBotMessage.current = null;
        shouldChangeChatId.current = true;
        if (lastMsgReason === 'end') {
          setInputData({
            ...inputData,
            typing: false
          });
        }
        return;
      }
      // Ho superato il numero massimo di token, aggiungo un nuovo messaggio
      shouldChangeChatId.current = false;
      sendToSocket(undefined, [...messages.messagesSocket]);
    };

    const msgDelta: string = msg?.choices[0]?.delta?.content ?? '';
    const annotations: any[] = msg?.delta?.content[0].text.annotations ?? [];
    const attachments: any[] = msg?.delta?.attachments ?? [];

    if (currentChatId) {
      const currentTextBot = lastBotMessage?.current?.text ?? '';
      let newText = currentTextBot + msgDelta;
      if (annotations.length > 0 && attachments.length > 0) {
        for (const file of annotations) {
          newText = newText.replace(
            `${file.text}`,
            `${process.env.REACT_APP_FILE_ENDPOINT}/file/${file.file_path.file_id}`
          ).replace(
            /\[(.*?)\]\((.*?\/file\/file-.*?)\)/,
            `<a href="${process.env.REACT_APP_FILE_ENDPOINT}/file/${file.file_path.file_id}" target="_blank">$1</a>`
          );
        }
      }
      // Controllo se ci sono link immagine e faccio il resize con lo style se necessario
      newText = newText.replace(
        /!\[(.*?)\]\((.*?\.png|.*?\.jpe?g|.*?\.gif)\)/,
        '<img src="$2" alt="$1" style="max-width: 1000px; height: auto"/>'
      );
      lastBotMessage.current!.text = newText;
    }

    const newMsg: MessageChat = {
      sender: 'BOT',
      text: msgDelta // fare append
    };

    /**
     * Se sono nel caso di finish reason length,
     * Non permetto la creazione di un nuovo box messaggi.
     */
    if (!shouldChangeChatId.current) {
      return;
    }

    if (msg.id !== currentChatId) {
      setCurrentChatId(msg.id);

      /* Se sto rigenerando un messaggio (quindi l'ultimo è quello del BOT) lo rimuovo dalla lista dei
       messaggi della socket per inserire il più recente. */
      let newMessagesSocket = [...messages.messagesSocket];
      if (messages.messagesSocket[messages.messagesSocket.length - 1].sender === 'BOT') {
        newMessagesSocket = [...messages.messagesSocket.slice(0, messages.messagesSocket.length - 1)];
      }

      setMessages({
        messagesRendered: [...messages.messagesRendered, newMsg],
        messagesSocket: [...newMessagesSocket, newMsg]
      });
      lastBotMessage.current = newMsg;
    }
  }, [lastMessage]);

  useEffect(() => {
    async function sendMessagePost (currentMessage: string, regenerate: boolean): Promise<void> {
      const prevMessages = [...messages.messagesSocket];

      prevMessages.pop();

      /*
      Se sto rigenerando il messaggio, prendo tutti i messaggi precedenti all'ultimo messaggio inviato dall'utente,
      così da evitare di prendere le risposte dell'ultimo messaggio.
      */
      if (regenerate) {
        prevMessages.pop();
      }

      await sendToSocket(currentMessage, prevMessages);

      setInputData({
        ...inputData,
        typing: true
      });

      setNewMessage(undefined);
    }

    if (newMessage != null) {
      sendMessagePost(newMessage.msg, newMessage.regenerate);
    }
  }, [newMessage]);

  const handleSendMessage = (msg: any): void => {
    // Se la connessione è chiusa, la riapro
    const connectionStatus = ReadyState[readyState];
    if (connectionStatus === 'CLOSED') {
      connectSocket();
    }
    const newFiles: MessageChat[] = [];
    for (const file of files.fileList) {
      newFiles.push({
        sender: 'FILE',
        text: `${file.name}`,
        className: 'msg-file'
      });
    }
    const newMsg: MessageChat = {
      sender: 'USER',
      text: sanitizeText(msg)
    };

    // Passo su sentry parte dell'ultimo messaggio inviato.
    setContext('User last message', {
      text: typeof msg === 'string' ? msg.substring(0, 200) : 'empty string'
    });
    setMessages({
      messagesRendered: [...messages.messagesRendered, ...newFiles, newMsg],
      messagesSocket: [...messages.messagesSocket, newMsg]
    });
    setNewMessage({
      msg,
      regenerate: false
    });
    setInputText('');
  };

  const handleInput = useCallback((innerHtml: string): void => {
    setInputText(innerHtml);
  }, [setInputText]);

  const handlePaste = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();

    const selection = window.getSelection();

    if (selection) {
      const range = selection.getRangeAt(0);

      const text = e.clipboardData.getData('text/plain');

      // Elimina il testo selezionato, se è stato selezionato un testo.
      range.deleteContents();

      // Incolla il testo alla posizione del cursore.
      range.insertNode(document.createTextNode(text));

      // Muove il cursore alla fine del testo incollato.
      selection.collapseToEnd();
      const editor = document.querySelector('.cs-message-input__content-editor')!;

      if (editor) {
        // Il testo è stato inserito nell'editor senza cambiare lo stato, quindi ho bisogno di aggiornarlo.
        // Per farlo abbiamo bisogno di triggerare l'evento input manualmente.
        editor.dispatchEvent(new Event('input', { bubbles: true }));
      }
    }
  }, []);

  // Hook per gestire l'invio dell'ack ogni minuto per non far scadere l'endpoint di Api Gateway
  useEffect(() => {
    const handleAck = (): void => {
      // Se la connessione è chiusa, non invio l'ack
      const connectionStatus = ReadyState[readyState];
      if (connectionStatus !== 'OPEN') {
        return;
      }
      sendMessage('ack');
    };

    const interval = setInterval(handleAck, 300000);

    return () => { clearInterval(interval); };
  }, [readyState]);

  useEffect(() => {
    const handleTimeout = (): void => {
      try {
        const currentMsgData = (lastMessage != null) ? JSON.parse(lastMessage?.data) : {};
        const finishReason = currentMsgData?.choices ? currentMsgData?.choices[0]?.finish_reason : '';
        if ((finishReason !== 'stop' || (finishReason === 'stop' && !currentChatId)) && inputData.typing) {
          setCurrentChatId(null);
          lastBotMessage.current = null;
          setInputData({
            typing: false,
            disabled: true
          });

          const errorMsg: MessageChat = {
            sender: 'BOT',
            text: 'Ops! Qualcosa è andato storto. Per favore, clicca su Reset Chat e reinserisci il prompt. Se il problema persiste, inviaci una richiesta di supporto! :)',
            className: 'msg-error'
          };

          setMessages({
            messagesRendered: [...messages.messagesRendered, errorMsg],
            messagesSocket: [...messages.messagesSocket, errorMsg]
          });
          const socket = getWebSocket();
          setContext('Socket', {
            latestMessages: rawSocketData.current.messageHistory,
            socketState: rawSocketData.current.socketState
          });
          socket?.close();
          /**
           * Gestisco il caso in cui il client
           * abbia problemi di connettività, in quel caso
           * traccio la situazione della rete su Sentry
           * aggiungendo un breadcrumb ma non mando esplicitamente l'eccezione.
           */
          if (!navigator.onLine) {
            addConnectivityIssueBreadcrumb();
            return;
          }
          captureException('timeout', {
            level: 'info',
            tags: {
              component: 'Chat',
              action: 'handleTimeout'
            },
            user
          });
        }
      } catch (error) {
        setContext('Socket', {
          latestMessages: rawSocketData.current.messageHistory,
          socketState: rawSocketData.current.socketState
        });
        captureException(error, {
          tags: {
            component: 'Chat',
            action: 'handleTimeout'
          },
          user
        });
        setCurrentChatId(null);
        lastBotMessage.current = null;
        setInputData({
          typing: false,
          disabled: true
        });

        const errorMsg: MessageChat = {
          sender: 'BOT',
          text: 'Ops! Qualcosa è andato storto. Per favore, clicca su Reset Chat e reinserisci il prompt. Se il problema persiste, inviaci una richiesta di supporto! :)',
          className: 'msg-error'
        };

        setMessages({
          messagesRendered: [...messages.messagesRendered, errorMsg],
          messagesSocket: [...messages.messagesSocket, errorMsg]
        });
        console.error('error', error);
      }
    };
    const timer = setTimeout(handleTimeout, 300000);

    return () => { clearTimeout(timer); };
  }, [lastMessage, inputData, messages, user, currentChatId, rawSocketData]);

  /**
   * Reinvia l'ultimo messaggio così da rigenerare la risposta della chat.
   */
  const regenerateResponse = (): void => {
    // Se la connessione è chiusa, la riapro
    const connectionStatus = ReadyState[readyState];
    if (connectionStatus === 'CLOSED') {
      connectSocket();
    }
    let lastUserMessage: MessageChat | undefined;

    // Se sto rigenerando il messaggio, setto come newMessage l'ultimo messaggio dell'utente.
    messages.messagesSocket.forEach((item: MessageChat) => {
      if (item.sender === 'USER') {
        lastUserMessage = item;
      }
    });

    if (lastUserMessage?.text) {
      setNewMessage({
        msg: lastUserMessage.text,
        regenerate: true
      });
    }
  };

  const isAudio = (type: string): boolean => {
    return (
      type.endsWith('mp3') ||
      type.endsWith('mp4') ||
      type.endsWith('mpeg') ||
      type.endsWith('mpga') ||
      type.endsWith('m4a') ||
      type.endsWith('wav') ||
      type.endsWith('webm')
    );
  };

  const handleUpload = async (e: any): Promise<void> => {
    const errorMsg: MessageChat = {
      sender: 'BOT',
      text: 'Ops! Qualcosa è andato storto nel caricamento. Per favore, ritenta. Se il problema persiste, inviaci una richiesta di supporto! :)',
      className: 'msg-error'
    };
    Array.from(e.target.files).forEach(async (file: any) => {
      try {
        if (fileCount.fileTot >= MAX_NUMBER_FILES) {
          errorMsg.text = 'Ops! Hai inserito troppi file. Per favore, clicca su Reset Chat e comincia una nuova conversazione.';
          throw Error('You uploaded 20 files. Please start a new conversation.');
        }
        if (file.size > MAX_SIZE_FILE) {
          errorMsg.text = 'Ops! Hai inserito un file troppo grande. Per favore, carica file che siano sotto i 512 MB.';
          throw Error('File size exceeds 512 MB. Please choose a smaller file.');
        }

        const type = file.type === 'text/csv' ? 'application/csv' : file.type;
        if (isAudio(type) && file.size > MAX_SIZE_AUDIO_FILE) {
          errorMsg.text = 'Ops! Hai inserito un file audio troppo grande. Per favore, carica un audio che sia sotto i 25 MB.';
          throw Error('File audio size exceeds 25 MB. Please choose a smaller file.');
        }

        let upload;
        try {
          upload = await Storage.put(`${(file.name as string).toLowerCase()}`, file, {
            level: 'private',
            contentType: type
          });
        } catch (err: any) {
          errorMsg.text = 'Ops! Il formato del file che hai scelto non è supportato. Posso gestire i file con queste estensioni: c, cpp, css, csv, docx, gif, go, html, java, jpeg, jpg, js, json, md, pdf, php, pkl, png, pptx, py, rb, tar, tex, ts, txt, webp, xlsx, xml, zip. Per favore, clicca su Reset Chat e scegli un altro file.';
          throw Error('File format not supportes. Please choose another file.');
        }

        const newFile: UploadedFile = {
          key: `private/${identityId}/${upload.key}`,
          name: file.name,
          type
        };

        setFiles(prevState => ({
          fileList: [...prevState.fileList, newFile]
        }));
        setFileTot(prevState => ({
          fileTot: prevState.fileTot + 1
        }));
      } catch (error) {
        setMessages(prevState => ({
          messagesRendered: [...prevState.messagesRendered, errorMsg],
          messagesSocket: [...prevState.messagesSocket, errorMsg]
        }));
        console.error('error', error);
      }
    });
  };

  const onInputClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  const handeAttachClick = async (): Promise<void> => {
    document.getElementById('file')!.click();
  };

  const onCloseClick = (e: MouseEvent, key: string): void => {
    e.stopPropagation();
    setFiles(prevState => ({
      fileList: [...prevState.fileList.filter((card) => card.key !== key)]
    }));
  };

  /**
   * Blocca la generazione del messaggio di risposta della chat.
   */
  const stopGenerating = useCallback(() => {
    setEnabledRegenerate(false);
    const socket = getWebSocket();
    socket?.close();
    setCurrentChatId(null);
    setInputData({
      typing: false,
      disabled: false
    });
    lastBotMessage.current = null;
  }, [getWebSocket]);

  return declaimerClosed
    ? <Box
      sx={{ width: '100%', height: '100%' }}>
        <MainContainer responsive style={{ border: 'none' }}>
          <input id="file" type="file" accept="text/x-c, text/x-csharp, text/x-c++, application/vnd.openxmlformats-officedocument.wordprocessingml.document, text/html, text/x-java, application/json, text/markdown, application/pdf, text/x-php, application/vnd.openxmlformats-officedocument.presentationml.presentation, text/x-python, text/x-script.python, text/x-ruby, text/x-tex, text/plain, text/css, text/javascript, application/x-sh, application/typescript, application/csv, image/jpeg, image/gif, application/octet-stream, image/png, application/x-tar, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, text/xml, application/zip, text/csv, application/typescript, audio/mpeg, video/mp4, video/mpeg, audio/mpeg, audio/mp4, audio/wav, video/webm, text/x-go, image/webp" onClick={onInputClick} onChange={handleUpload} multiple style={{ opacity: 0, width: 0 }} />
          <ChatContainer>
            <MessageList typingIndicator={inputData.typing ? <TypingIndicator content="ChatGPT360 sta scrivendo.." /> : <></>}>
              {messages.messagesRendered.map((message, index) => (
                createMessage(message, index, inputData.typing, messages.messagesRendered.length - 1 === index, lastBotMessage.current, setPromptModalState)
              ))}
            </MessageList>
            <InputToolbox style={{ display: 'flex', flexDirection: 'column' }}>
              <Box textAlign="center" width="100%">
                {
                  !inputData.typing &&
                  messages.messagesRendered.length > 1 &&
                  messages.messagesRendered[messages.messagesRendered.length - 1]?.className !== 'msg-error' &&
                  <Button disabled={!enabledRegenerate} onClick={regenerateResponse}>Regenerate Response</Button>
                }
                { inputData.typing && <Button onClick={stopGenerating}>Stop Generating</Button> }
                <Prompts modalState={promptModalState} setModalState={setPromptModalState} setInputText={setInputText} />
              </Box>
              <Box id="appended-files" component="div" width="100%" style={{ paddingLeft: '20px', display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '10px' }}>
              {files.fileList.map((file: UploadedFile) => <Box key={file.key}><FileCard name={file.name} key={file.key} type={file.type} onCloseClick={(e: any) => { onCloseClick(e, file.key); }}/></Box>)}
              </Box>
            </InputToolbox>
            <MessageInput
              className='notranslate'
              disabled={inputData.disabled || inputData.typing}
              onPaste={handlePaste}
              sendDisabled={inputData.disabled || inputData.typing}
              sendOnReturnDisabled={inputData.disabled || inputData.typing}
              value={inputText}
              onChange={handleInput}
              placeholder="Scrivi qualcosa"
              attachButton={true}
              onAttachClick={handeAttachClick}
              onSend={handleSendMessage}
            />
          </ChatContainer>
          <AppToaster />
        </MainContainer>
    </Box>
    : <Declaimer />;
};

export { Chat };
